import { computed, ref } from "vue";
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () =>
{
  const profile= ref({name: '', email: '', email_verified_at: null, is_delegate_mode: false});
  const account= ref<Account>({ admin_user_id: null, name: "", first_name: "", last_name: "", id: null, email: '', bio: '', interests: [], profile_pic: {}, profile_upload_id: null, profile_pic_url: null, onboarding_step: 0});
  const timeline= ref({id: null});

  const getUser = computed(() => profile.value);
  const getAccount = computed(() => account.value);
  const getTimeline = computed(() => timeline.value);

  const updateProfileStore = (newProfile: {}) => {
    profile.value = newProfile;
  };

  const updateAccountStore = (newAccount: Account) => {
    account.value = newAccount;
  };

  return { profile, account, timeline, getUser, getAccount, getTimeline,  updateProfileStore, updateAccountStore }
});
